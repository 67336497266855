import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        token: null
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        deleteToken: (state) => {
            state.token = null
        }
    }
});

export const { setToken, deleteToken } = tokenSlice.actions;
export default tokenSlice.reducer;