import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        currentUser: null
    },
    reducers: {
        setCurrentUsers: (state, action) => {
            state.currentUser = action.payload;
        },
        deleteCurrentUser : (state) => {
            state.currentUser = null
        }
    }
});

export const { setCurrentUsers, deleteCurrentUser} = usersSlice.actions;
export default usersSlice.reducer;