import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material";

export const tokens = (mode) => ({
  ...(mode === "light"
    ? {
        primary: "#FFFFFF",
        background: "#20262E",
        shadow: "#eee",
        border: "#000",
        profileIcon: "#000"
      }
    : {
        primary: "#20262E",
        background: "#FFFFFF",
        shadow: "#FFF",
        border: "#FFF",
        profileIcon: "#20262E"
      }),
});

export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "light"
        ? {
            primary: {
              main: "#3C4048",
            },
            secondary: {
              main: "#000000",
            },
            // body background color
            background: {
              default: "#FFFFFF",
            },
          }
        : {
            // dark mode
            primary: {
              main: "#FFFFFF",
            },
            secondary: {
              main: "#121212",
            },
            background: {
              default: "#20262E",
            },
          }),
    },
    typography: {
      fontFamily: ["Itim", "cursive"].join(","),
      fontSize: 16,
      h1: {
        fontFamily: ["Itim", "cursive"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Itim", "cursive"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Itim", "cursive"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Itim", "cursive"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Itim", "cursive"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Itim", "cursive"].join(","),
        fontSize: 14,
      },
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  return [theme, colorMode];
};
