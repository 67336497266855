import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import NoPage from "./pages/NoPage";
import Home from "./pages/Home";
import About from "./pages/about";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ProctectedRoute from "./components/ProctectedRoute";
import CarPricePredictor from "./pages/CarPricePredictor";
import Loader from "./components/Loader";
import { ColorModeContext, useMode } from "./theme";

function App() {
  const { loading } = useSelector((state) => state.loadersReducer);
  const [theme, colorMode] = useMode();

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Toaster position="top-center" reverseOrder={false} />
      <BrowserRouter>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="*" element={<NoPage />} />
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/sign-up" element={<Register />} />
              <Route path="/sign-in" element={<Login />} />
              <Route
                path="/car-price-predictor"
                element={
                  <ProctectedRoute>
                    <CarPricePredictor />
                  </ProctectedRoute>
                }
              />
            </Routes>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
