import React from 'react'
import { Box, Typography } from '@mui/material'

function NoPage() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant='h3'>
            Sorry page not found!
        </Typography>
    </Box>
  )
}

export default NoPage
