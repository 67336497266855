import React from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { RegisterUser } from "../../apiCalls/user";
import { toast } from "react-hot-toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { HideLoading, ShowLoading } from "../../redux/loadersSlice";

function Register() {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRegister = async () => {
    try {
      dispatch(ShowLoading());
      const response = await RegisterUser(formData);
      if (response.success) {
        toast.success(response.message);
        navigate("/sign-in");
        setFormData({
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      } else {
        toast.error(response.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      toast.error("Error in registering user");
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (regex.test(e.target.value)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email address");
      }
    } else if (e.target.password) {
      if (e.target.value.length >= 8) {
        setPasswordError("");
      } else {
        setPasswordError("Password must have atleast 8 characters");
      }
    } else if (e.target.confirmPassword) {
      if (formData.password !== e.target.value) {
        setConfirmPasswordError("Please enter correct password");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <Grid align="center">
        <Paper
          elevation={20}
          sx={{
            p: "30px 20px",
            width: "400px",
            minWidth: "200px",
            m: "20px auto",
          }}
        >
          <Grid align="center">
            <AccountCircleIcon />
            <Typography>Register</Typography>
            <Typography variant="caption">
              Please fill out all details to create an account!
            </Typography>
          </Grid>
          <Box m="2px auto" component="form" autoComplete="off">
            <TextField
              variant="standard"
              fullWidth
              label="Name"
              name="name"
              placeholder="Enter your full name"
              value={formData.name}
              onChange={handleInputChange}
              sx={{ mb: 1 }}
              required
              helperText="Name must contain at least 3 characters"
            />
            <TextField
              variant="standard"
              fullWidth
              type="email"
              label="Email"
              placeholder="Enter your email id"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              sx={{ mb: 1 }}
              required
              helperText={emailError}
              error={Boolean(emailError)}
            />
            <TextField
              variant="standard"
              fullWidth
              label="Create new password"
              name="password"
              placeholder="Create a password"
              value={formData.password}
              onChange={handleInputChange}
              sx={{ mb: 1 }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              helperText={passwordError}
              error={Boolean(passwordError)}
            />
            <TextField
              variant="standard"
              fullWidth
              label="Confirm password"
              name="confirmPassword"
              placeholder="Confirm password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              sx={{ mb: 1 }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={confirmPasswordError}
              error={Boolean(confirmPasswordError)}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                my: 2,
              }}
              disabled={
                formData.name.length > 3 &&
                formData.email.length > 3 &&
                formData.password.length > 3 &&
                formData.password === formData.confirmPassword
                  ? false
                  : true
              }
              onClick={handleRegister}
            >
              Register
            </Button>
          </Box>
          <Link to="/sign-in">Already have an account? Login</Link>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default Register;
