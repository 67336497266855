import { axiosInstance } from ".";
import { RegisterUserEndpoint, LoginUserEndpoint, GetUserDetailsEndpoint } from '../config/apiConstants';

export const RegisterUser = async (payload) => {
    try {
        const response = await axiosInstance.post(RegisterUserEndpoint, payload);
        return response.data;
    } catch(error) {
        throw error || error.response.data;
    }
}

export const LoginUser = async (payload) => {
    try {
        const response = await axiosInstance.post(LoginUserEndpoint, payload);
        return response.data;
    } catch(error) {
        throw error || error.response.data;
    }
}

export const GetUserDetails = async () => {
    try {
        const response = await axiosInstance.get(GetUserDetailsEndpoint);
        return response.data;
    } catch(error) {
        throw error || error.response.data;
    }
}

