import React from "react";
import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";
import { tokens } from "../../theme";

function Home() {
  const { currentUser } = useSelector((state) => state.usersReducer);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <React.Fragment>
      <Navbar />
      <Box
        mx={6}
        mt={5}
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
        backgroundColor={`${colors.primary}`}
        gap={3}
      >
        <Box
          width="300px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box position="relative">
            <Box
              sx={{
                width: "270px",
                height: "100px",
                position: "absolute",
                backgroundColor: "#F99417",
                zIndex: -1000,
                opacity: 0.7,
                borderRadius: "40% 80% 50% 70%",
                top: 25,
              }}
            ></Box>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Itim, cursive",
              }}
            >
              Predict your dream car price with machine learning
            </Typography>
          </Box>
          <Typography
            sx={{
              opacity: 1,
              color: "#ccc",
              my: 2,
            }}
          >
            Create your account to predict your car price with machine learning
          </Typography>
          {currentUser ? (
            <Link to="/car-price-predictor" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  marginTop: 2,
                  py: 1,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                Go to Car Price Predictor
              </Button>
            </Link>
          ) : (
            <Link to="/sign-up" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  marginTop: 2,
                  marginBottom: 3,
                  paddingX: 4,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                Get Started
              </Button>
            </Link>
          )}
        </Box>
        <Box>
          <img
            src="./home_images/car.png"
            style={{
              width: isMobile ? "300px" : "500px",
              minWidth: "200px",
            }}
            alt="car"
          />
        </Box>
      </Box>
      <Box
        mx={6}
        mt={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          sx={{
            opacity: 1,
            color: "#ccc",
          }}
        >
          {
            "Car Price Prediction Project Using Machine Learning (Linear Regression)"
          }
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default Home;
