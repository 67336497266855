import React, { useEffect} from 'react'
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetUserDetails } from '../apiCalls/user';
import { HideLoading, ShowLoading } from '../redux/loadersSlice';
import { setCurrentUsers } from '../redux/usersSlice';
import { axiosInstance } from '../apiCalls';

function ProctectedRoute({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.tokenReducer);
  const localStorageToken = localStorage.getItem('jwtToken');

  const getUser = async () => {
    try {
      dispatch(ShowLoading());
      const response = await GetUserDetails();
      if(response.success) {
        dispatch(setCurrentUsers(response.data));
      } else {
        toast.error(response.message);
        navigate('/sign-in');
      }
      dispatch(HideLoading());
    } catch(error) {
      dispatch(HideLoading());
      toast.error(error);
      navigate('/sign-in');
    }
  }

  useEffect(() => {
    if(localStorageToken) {
      getUser();
    } else {
      navigate('/sign-in');
    }
  }, []);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default ProctectedRoute
