import { configureStore } from "@reduxjs/toolkit";

import loadersReducer from "./loadersSlice";
import usersReducer from "./usersSlice";
import tokenReducer from "./tokenSlice";

const store = configureStore({
  reducer: {
    loadersReducer,
    usersReducer,
    tokenReducer
  }
});

export default store;
