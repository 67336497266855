import React from "react";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Navbar from "../../components/Navbar";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { tokens } from "../../theme";

function About() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [visible, setVisible] = React.useState(false);

  window.addEventListener("scroll", () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition >= 50) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  });

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <Navbar />
      <Box mx={isMobile ? 5 : 10} my={6} position="relative">
        {visible && (
          <IconButton
            sx={{
              p: 1,
              border: `1px solid ${colors.background}`,
              position: "fixed",
              right: isMobile ? 25 : 50,
              bottom: 100,
            }}
            onClick={handleScrollToTop}
          >
            <ExpandLessIcon />
          </IconButton>
        )}
        <Typography variant="h1" pl={isMobile ? 0 : 3} fontWeight="bold">
          Introduction
        </Typography>
        <Divider />
        <Typography sx={{ p: isMobile ? 0 : 3 }} mt={isMobile ? 3: 1}>
          We are proud to introduce our latest project, a car price predictor
          web application that utilizes machine learning, Flask, and the MERN
          stack. Our team consists of three highly skilled developers: Himanshu,
          Niranjan Bhagat, and Rohit.
        </Typography>
        <Typography sx={{ p: isMobile ? 0 : 3 }}>
          Our web application is designed to accurately predict the price of a
          car based on various factors such as company, model, year, fuel type,
          and other relevant features. Using our application, users can quickly
          and easily obtain an estimate of the price they should expect to pay
          for a car they're interested in buying or selling.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={3}
          flexWrap="wrap"
          my={2}
        >
          <img
            src="./about_images/flask.png"
            alt="flask"
            style={{ width: "200px", border: "1px solid #000" }}
          />
          <img
            src="./about_images/mongodb.png"
            alt="mongodb"
            style={{ width: "200px", border: "1px solid #000" }}
          />
          <img
            src="./about_images/express.png"
            alt="express"
            style={{ width: "200px", border: "1px solid #000" }}
          />
          <img
            src="./about_images/reactjs.png"
            alt="reactjs"
            style={{ width: "200px", border: "1px solid #000" }}
          />
          <img
            src="./about_images/nodejs.png"
            alt="nodejs"
            style={{ width: "200px", border: "1px solid #000" }}
          />
        </Box>
        <Typography sx={{ p: isMobile ? 0 : 3 }}>
          Our application uses Flask, a Python web framework, to build a RESTful
          API that communicates with our machine learning model. Rohit, our data
          scientist, has expertise in machine learning and data analysis and is
          responsible for creating a model that accurately predicts car prices.
          Niranjan Bhagat is a skilled Flask developer who is responsible for
          integrating the Flask API with our MERN stack, ensuring a seamless
          user experience. Himanshu, our React expert, is responsible for
          building a user-friendly and intuitive front-end that provides users
          with an easy-to-use interface for obtaining car price predictions.
        </Typography>
        <Typography sx={{ p: isMobile ? 0 : 3 }}>
          Together, our team has brought their skills and expertise to create an
          innovative and powerful web application that makes buying and selling
          cars more accessible and transparent. With our car price predictor,
          you can be confident that you're getting a fair deal on your next car
          purchase.
        </Typography>
        <Typography variant="h1" pl={isMobile ? 0 : 3} fontWeight="bold" mt={3}>
          Deployment
        </Typography>
        <Divider />
        <Typography sx={{ p: isMobile ? 0 : 3 }} mt={isMobile ? 3: 1}>
          We have deployed our project on{" "}
          <a href="https://render.com/" target="_blank" rel="render">
            Render.com
          </a>{" "}
          which is a unified cloud to build and run all your apps and websites
          with free TLS certificates, a global CDN, DDoS protection, private
          networks, and auto deploys from Git.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={3}
          flexWrap="wrap"
          my={2}
        >
          <img
            src="./about_images/docker.png"
            alt="flask"
            style={{ width: "200px" }}
          />
        </Box>
        <Typography sx={{ p: isMobile ? 0 : 3 }}>
          We used <a href="https://www.docker.com/" target="_blank" rel="docker">Docker</a> to deploy our python application. Docker takes away
          repetitive, mundane configuration tasks and is used throughout the
          development lifecycle for fast, easy and portable application
          development – desktop and cloud. Docker’s comprehensive end to end
          platform includes UIs, CLIs, APIs and security that are engineered to
          work together across the entire application delivery lifecycle.
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default About;
