import { axiosInstance } from ".";
import { GetCarPricePredictorDataEndpoint, PredictCarPriceEndpoint } from "../config/apiConstants";

export const GetCarPriceData = async () => {
    try {
        const response = await axiosInstance.get(GetCarPricePredictorDataEndpoint);
        return response.data;
    } catch(error) {
        throw error || error.response.data;
    }
}

export const PredictCarPrice = async (payload) => {
    try {
        const response = await axiosInstance.post(PredictCarPriceEndpoint, payload);
        return response.data;
    } catch(error) {
        throw error || error.response.data;
    }
}