// const baseUrl = 'http://localhost:5000';

const baseUrl = 'https://car-price-predictor-backend.onrender.com';

export const RegisterUserEndpoint = baseUrl + '/api/users/register';

export const LoginUserEndpoint = baseUrl + '/api/users/login';

export const GetUserDetailsEndpoint = baseUrl + '/api/users/get-user-details';

export const GetCarPricePredictorDataEndpoint = baseUrl + '/api/python/car-price-predictor-data';

export const PredictCarPriceEndpoint = baseUrl + '/api/python/predict-car-price';
