import React from "react";
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Typography,
  Select,
  MenuItem,
  TextField,
  Box,
  Button,
  useMediaQuery,
} from "@mui/material";
import Navbar from "../../components/Navbar";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { HideLoading, ShowLoading } from "../../redux/loadersSlice";
import {
  GetCarPriceData,
  PredictCarPrice,
} from "../../apiCalls/carPricePredictor";

function CarPricePredictor() {
  const [predictorOptionsData, setPredictorOptionsData] = React.useState({
    companies: [],
    models: [],
    yearOfPurchase: [],
    fuelTypes: [],
  });

  const [carPriceData, setCarPriceData] = React.useState({
    company: "",
    model: "",
    yearOfPurchase: "",
    fuelType: "",
    numberOfKilometers: "",
  });

  const [predictedData, setPredictedData] = React.useState("");
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const getCarPricePredictorData = async () => {
    try {
      dispatch(ShowLoading());
      const response = await GetCarPriceData();
      if (response.success) {
        setPredictorOptionsData({
          companies: response.data.companies,
          models: response.data.car_models,
          yearOfPurchase: response.data.year,
          fuelTypes: response.data.fuel_type,
        });
      } else {
        toast.error(response.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      toast.error("Error in getting data from server");
    }
  };

  React.useEffect(() => {
    getCarPricePredictorData();
  }, []);

  const filteredModels = predictorOptionsData.models.filter(
    (model) => model.split(' ')[0] === carPriceData.company
  );

  const handlePredictPriceClick = async () => {
    try {
      dispatch(ShowLoading());
      const response = await PredictCarPrice(carPriceData);
      if (response.success) {
        setCarPriceData({
          company: "",
          model: "",
          yearOfPurchase: "",
          fuelType: "",
          numberOfKilometers: "",
        });
        toast.success(response.message);
        setPredictedData(response.data);
      } else {
        toast.error(response.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      toast.error("Error in predicting price");
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <Grid align="center">
        <Paper
          elevation={20}
          sx={{
            p: "30px 20px",
            width: isMobile ? "400px" : "550px",
            minWidth: "200px",
            m: "20px auto",
          }}
        >
          <Grid align="center">
            <Typography variant="h3" fontWeight="bold">
              Welcome to Car Price Predictor
            </Typography>
            <Typography variant="caption">
              This app predicts the price of a car you want to sell. Try filling
              these details below:
            </Typography>
            <Divider />
            <Box mt={3}>
              <FormControl fullWidth>
                {/* Select Company */}
                <InputLabel>Select Company</InputLabel>
                <Select
                  onChange={(event) =>
                    setCarPriceData({
                      ...carPriceData,
                      company: event.target.value,
                    })
                  }
                  label="Select Company"
                  value={carPriceData.company}
                  sx={{ mb: 2 }}
                  disabled={Boolean(predictedData)}
                >
                  {predictorOptionsData.companies.map((company) => (
                    <MenuItem value={company}>{company}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Select Model */}
              <FormControl fullWidth>
                <InputLabel>Select Model</InputLabel>
                <Select
                  onChange={(event) =>
                    setCarPriceData({
                      ...carPriceData,
                      model: event.target.value,
                    })
                  }
                  label="Select Model"
                  value={carPriceData.model}
                  sx={{ mb: 2 }}
                  disabled={Boolean(predictedData)}
                >
                  {filteredModels.map((model) => (
                    <MenuItem value={model}>{model}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Select Year of Purchase */}
              <FormControl fullWidth>
                <InputLabel>Select Year of Purchase</InputLabel>
                <Select
                  onChange={(event) =>
                    setCarPriceData({
                      ...carPriceData,
                      yearOfPurchase: event.target.value,
                    })
                  }
                  label="Select Year of Purchase"
                  value={carPriceData.yearOfPurchase}
                  sx={{ mb: 2 }}
                  disabled={Boolean(predictedData)}
                >
                  {predictorOptionsData.yearOfPurchase.map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Select Fuel Type */}
              <FormControl fullWidth>
                <InputLabel>Select Fuel Type</InputLabel>
                <Select
                  onChange={(event) =>
                    setCarPriceData({
                      ...carPriceData,
                      fuelType: event.target.value,
                    })
                  }
                  label="Select Fuel Type"
                  value={carPriceData.fuelType}
                  sx={{ mb: 2 }}
                  disabled={Boolean(predictedData)}
                >
                  {predictorOptionsData.fuelTypes.map((fuelType) => (
                    <MenuItem value={fuelType}>{fuelType}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Number of kilometers travelled */}
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Enter Number of kilometers travelled"
                onChange={(event) =>
                  setCarPriceData({
                    ...carPriceData,
                    numberOfKilometers: event.target.value,
                  })
                }
                value={carPriceData.numberOfKilometers}
                disabled={Boolean(predictedData)}
              />
              {predictedData ? (
                <Box mt={3}>
                  <Typography variant="h4" sx={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
                    {"Predicted Car Price: "}&#8377;
                    {predictedData}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                      mt: 3,
                      fontSize: "1rem",
                    }}
                    onClick={() => setPredictedData("")}
                    color="error"
                  >
                    Clear
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    fontWeight: "bold",
                    mt: 3,
                    fontSize: "1rem",
                  }}
                  disabled={
                    carPriceData.company.length &&
                    carPriceData.model.length &&
                    carPriceData.yearOfPurchase &&
                    carPriceData.fuelType.length &&
                    carPriceData.numberOfKilometers.length
                      ? false
                      : true
                  }
                  onClick={handlePredictPriceClick}
                >
                  Predict Price
                </Button>
              )}
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default CarPricePredictor;
