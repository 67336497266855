import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteToken } from "../redux/tokenSlice";
import { HideLoading, ShowLoading } from "../redux/loadersSlice";
import { deleteCurrentUser } from "../redux/usersSlice";
import { ColorModeContext, tokens } from "../theme";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

function Navbar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const { currentUser } = useSelector((state) => state.usersReducer);
  const [open, setOpen] = useState(false);

  const handleLogOut = () => {
    dispatch(ShowLoading());
    dispatch(deleteToken());
    dispatch(deleteCurrentUser());
    localStorage.removeItem("jwtToken");
    dispatch(HideLoading());
    navigate("/sign-in");
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={`${colors.primary}`}
        px={3}
        mb={1}
        flexWrap="wrap"
      >
        <Button onClick={() => navigate("/")}>
          <img
            src={`./logo/${theme.palette.mode}_theme_logo.png`}
            alt="logo"
            style={{ width: "100px" }}
          />
        </Button>
        {isMobile ? (
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={3}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: 5,
              }}
              onClick={() => navigate("/")}
            >
              Home
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderRadius: 5,
              }}
              onClick={() => navigate("/about-us")}
              disabled={Boolean(location.pathname === "/about-us")}
            >
              About us
            </Button>
            {currentUser ? (
              <>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 5,
                  }}
                  disabled={Boolean(location.pathname === "/car-price-predictor")}
                  onClick={() => navigate("/car-price-predictor")}
                >
                  Car Price Predictor
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 5,
                  }}
                  onClick={handleLogOut}
                >
                  Log Out
                </Button>
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={3}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 5,
                  }}
                  onClick={() => navigate("/sign-up")}
                  disabled={Boolean(location.pathname === "/sign-up")}
                >
                  Sign up
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 5,
                  }}
                  onClick={() => navigate("/sign-in")}
                  disabled={Boolean(location.pathname === "/sign-in")}
                >
                  Sign in
                </Button>
              </Box>
            )}
            <Box display="flex">
              <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "light" ? (
                  <LightModeOutlined />
                ) : (
                  <DarkModeOutlined />
                )}
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
      {open && isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: 5,
            }}
            onClick={() => navigate("/")}
          >
            Home
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: 5,
            }}
            onClick={() => navigate("/about-us")}
            disabled={Boolean(location.pathname === "/about-us")}
          >
            About us
          </Button>
          {currentUser ? (
            <>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 5,
                }}
                disabled={Boolean(location.pathname === "/car-price-predictor")}
                onClick={() => navigate("/car-price-predictor")}
              >
                Car Price Predictor
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 5,
                }}
                onClick={handleLogOut}
              >
                Log Out
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 5,
                }}
                onClick={() => navigate("/sign-up")}
                disabled={Boolean(location.pathname === "/sign-up")}
              >
                Sign up
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 5,
                }}
                onClick={() => navigate("/sign-in")}
                disabled={Boolean(location.pathname === "/sign-in")}
              >
                Sign in
              </Button>
            </>
          )}
          <Box display="flex">
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "light" ? (
                <LightModeOutlined />
              ) : (
                <DarkModeOutlined />
              )}
            </IconButton>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

export default Navbar;
