import React from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function Loader() {
  return (
    <Box sx={{
      backgroundColor: '#000',
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0.6,
      position: 'absolute',
      zIndex: 1000
    }}>
      <CircularProgress />
    </Box>
  );
}

export default Loader;
