import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@mui/material";
import { toast } from "react-hot-toast";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { LoginUser } from "../../apiCalls/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { HideLoading, ShowLoading } from "../../redux/loadersSlice";
import { setToken } from "../../redux/tokenSlice";

function Login() {
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const [loginPasswordError, setLoginPasswordError] = useState("");

  const handleLogin = async () => {
    try {
      dispatch(ShowLoading());
      const response = await LoginUser(formData);
      if (response.success) {
        toast.success(response.message);
        dispatch(setToken(response.data));
        localStorage.setItem('jwtToken', response.data);
        navigate("/car-price-predictor");
        setFormData({
          email: "",
          password: "",
        });
      } else {
        handleIncorrectPassword(response.message);
        toast.error(response.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      toast.error("Error in login");
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleIncorrectPassword = (message) => {
    if (message == "Incorrect Password!") {
      setLoginPasswordError(
        "The username or password you entered is incorrect."
      );
    } else {
      setLoginPasswordError("");
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <Grid align="center">
        <Paper
          elevation={20}
          sx={{
            p: "30px 20px",
            width: "400px",
            minWidth: "200px",
            m: "20px auto",
          }}
        >
          <Grid align="center">
            <AccountCircleIcon />
            <Typography>Login</Typography>
            <Typography variant="caption">
              Please login with your credentials!
            </Typography>
          </Grid>
          <Box m="2px auto" component="form" autoComplete="off">
            <TextField
              variant="standard"
              fullWidth
              type="email"
              label="Email"
              name="email"
              placeholder="Enter your email id"
              value={formData.email}
              onChange={handleInputChange}
              sx={{ mb: 1 }}
              required
              helperText="Enter the registered email address"
            />
            <TextField
              variant="standard"
              fullWidth
              label="Password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleInputChange}
              sx={{ mb: 1 }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              helperText={loginPasswordError}
              error={Boolean(loginPasswordError)}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  my: 2,
                }}
                disabled={
                  formData.email.length > 3 && formData.password.length > 3
                    ? false
                    : true
                }
                onClick={handleLogin}
              >
                Login
              </Button>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Checkbox />
                <Typography>Stay signed in</Typography>
              </Box>
            </Box>
          </Box>
          <Link to="/sign-up">Don't have an account? Register</Link>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default Login;
